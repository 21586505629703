<template>
  <v-container id="extras" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-car-child-seat"
      inline
      :title="$t('extras')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Extra</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="green" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <!-- <ConfirmationDialog v-model="isSure" @click="checkChanges" /> -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Extra -->
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- FOTO -->
                <v-col cols="12">
                  <center>
                    <!-- <v-row align="end"> -->
                    <div>
                      <ThumborImage
                        v-if="editedItem.imagen"
                        :avatarSize="100"
                        :imageUrl="editedItem.imagen"
                      />
                      <ThumborImage v-else :avatarSize="100" :imageUrl="editedItem.imagen" />
                    </div>
                    <div>
                      <v-btn
                        block
                        class="text-none"
                        color="primary"
                        depressed
                        elevation="5"
                        rounded
                        small
                        :loading="isSelecting"
                        @click="onButtonClick"
                      >
                        <v-icon v-if="selectedFile === null" left> mdi-cloud-upload </v-icon>
                        <v-icon v-else left> mdi-cloud-check </v-icon>
                        {{ buttonText }}
                      </v-btn>
                      <input
                        accept="image/*"
                        class="d-none"
                        ref="uploader"
                        type="file"
                        @change="onFileChanged"
                      />
                    </div>
                    <!-- </v-row> -->
                  </center>
                </v-col>

                <!-- Campo Extra -->
                <v-col cols="6">
                  <v-text-field
                    label="Extra*"
                    required
                    v-model="$v.editedItem.extra.$model"
                    :error-messages="extraErrors"
                    @blur="$v.editedItem.extra.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.extra.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Descripción -->
                <v-col cols="6">
                  <v-text-field
                    counter="50"
                    label="Descripción*"
                    required
                    v-model="editedItem.descripcion"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.descripcion.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Precio -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    hint="Introducir precio del producto"
                    label="Precio*"
                    maxlength="20"
                    prefix="$"
                    required
                    v-model="$v.editedItem.precio.$model"
                    :error-messages="precioErrors"
                    @blur="$v.editedItem.precio.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.precio.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Categoría -->
                <v-col cols="6">
                  <v-select
                    dense
                    hint="Seleciona Categoría"
                    item-text="categoria"
                    item-value="_id"
                    label="Categoría*"
                    persistent-hint
                    return-object
                    v-model="editedItem.categoria"
                    :items="servicesCategorys"
                    :menu-props="{ top: true, offsetY: true }"
                    @input="changed = true"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y Check List -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Extras -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="extras"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton de Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" @statusChanged="changeStatus(item)" />
        </template>

        <!-- Mostrar Imagen del Extra -->
        <template v-slot:[`item.imagen`]="{ item }">
          <!-- <img :src="item.imagen" alt="" width="50" height="50" /> -->
          <ThumborImage
            tipoImagen="foto"
            v-if="item.imagen"
            :avatarSize="45"
            :imageUrl="item.imagen"
          />
          <ThumborImage tipoImagen="imagen" v-else :avatarSize="45" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="extras" :dataFields="fields" fileName="Extras" />
    </base-material-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'
import ThumborImage from '@/components/general/ThumborImage.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
    ThumborImage,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    defaultButtonText: 'Cargar imagen del extra',
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSelecting: false,
    isSure: false,
    overlay: false,
    search: undefined,
    selectedFile: null,
    extras: [],
    headers: [
      { text: 'Imagen', value: 'imagen' },
      { text: 'Extra', align: 'start', sortable: false, value: 'extra' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Precio', value: 'precio' },
      { text: 'Categoría', value: 'categoria.categoria' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    servicesCategorys: [],
    defaultItem: {
      _id: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      descripcion: '',
      imagen: '',
      precio: '',
      extra: '',
      updatedAt: '',
      categoria: { _id: '', categoria: '' },
    },
    editedItem: {
      _id: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      descripcion: '',
      imagen: '',
      precio: '',
      extra: '',
      updatedAt: '',
      categoria: { _id: '', categoria: '' },
    },
    originalItem: {
      _id: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      descripcion: '',
      imagen: '',
      precio: '',
      extra: '',
      updatedAt: '',
      categoria: { _id: '', categoria: '' },
    },
    fields: {
      Extra: 'extra',
      Descripción: 'descripcion',
      precio: 'precio',
      Categoria: 'categoria.categoria',
      Activo: 'activo',
      Imagen: 'imagen',
    },
  }),
  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      extra: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      precio: { required, minLength: minLength(1), maxLength: maxLength(20) },
      // categoria: {
      //   categoria: { required },
      // },
    },
  },
  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },

    // categoriaErrors() {
    //   const errors = []
    //   if (!this.$v.editedItem.categoria.$dirty) return errors
    //   !this.$v.editedItem.sistema.required && errors.push('Este campo es requerido')
    //   return errors
    // },

    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    extraErrors() {
      const errors = []
      if (!this.$v.editedItem.extra.$dirty) return errors
      !this.$v.editedItem.extra.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.extra.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.extra.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    //Titulo del Modal si es Nuevo o Editar
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Extra' : 'Editar Extra'
    },

    precioErrors() {
      const errors = []
      if (!this.$v.editedItem.precio.$dirty) return errors
      !this.$v.editedItem.precio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.precio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.precio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  created() {
    this.obtenerExtras()
    this.obtenerCategoriasServicios()
  },
  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('extra/deleteExtra', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se eliminó con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cambia el estatus de un traslado
     */
    changeStatus(item) {
      this.isEditing = true
      this.editedIndex = this.extras.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.activo = !this.editedItem.activo
      let body = {
        extra: this.editedItem.extra,
        descripcion: this.editedItem.descripcion,
        precio: this.editedItem.precio,
        imagen: this.editedItem.imagen,
        categoria: this.editedItem.categoria,
        activo: this.editedItem.activo,
      }
      let payload = { id: this.editedItem._id, body: body }
      this.$store
        .dispatch('extra/editExtra', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: this.editedItem.activo ? 'Se activo el extra' : 'Se desactivo el extra',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      Object.assign(this.extras[this.editedIndex], this.editedItem)
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      this.changed = false
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.selectedFile = null
      this.$v.$reset()
    },

    /**
     * @description Elimina un Extra
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.extras.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.extras.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.extras.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // Para poder comparar cambios - 28/Sep/2020
      this.originalItem = Object.assign({}, item)
      // console.log(this.originalItem)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Obtiene las categorías de extras
     * @param {string} jwt JSON Web Token Valido
     * @return {array} arreglo con todos los extras
     */
    async obtenerCategoriasServicios() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('extraCategory/fetchExtraCategory', payload)
        .then((response) => {
          this.servicesCategorys = response.data
          // this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene los extras
     * @param {string} jwt JSON Web Token Valido
     * @return {array} arreglo con todaos los extras
     */
    async obtenerExtras() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('extra/fetchExtras', payload)
        .then((response) => {
          this.extras = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Verifica que se haya cargado la imagen al servidor
     * desde que se hace click en el botón
     */
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },

    /**
     * @description Verifica si se cargo un archivo
     * y llama al método para cargarlo al servidor
     */
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.uploadFile()
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo ya sea para un producto nuevo o
     * la edicion de un producto
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (this.editedItem.imagen == '') {
        this.editedItem.imagen = this.urlSinImagen
      }
      if (!this.$v.$invalid) {
        let body = {
          _id: this.editedItem._id,
          extra: this.editedItem.extra,
          descripcion: this.editedItem.descripcion,
          precio: this.editedItem.precio,
          imagen: this.editedItem.imagen,
          categoria: this.editedItem.categoria,
          activo: false,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('extra/editExtra', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.extras[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('extra/addExtra', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.extras.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
        this.changed = false
      }
    },

    //MANEJO DE ARCHIVOS
    /**
     * @description Carga un archivo al servidor
     */
    async uploadFile() {
      if (this.selectedFile != '') {
        let fd = new FormData()
        fd.append('attachment', this.selectedFile)
        let payload = { body: fd }
        this.$store
          .dispatch('extra/addImageExtra', payload)
          .then((response) => {
            // this.$store.commit('ALERT', {
            //   color: 'success',
            //   text: 'La imagen fue cargada con éxito',
            // })
            // this.editedItem.imagen = URL.createObjectURL(this.selectedFile)
            // this.editedItem.imagenAnterior = this.editedItem.imagen
            this.editedItem.imagen = response.data.url
            this.$refs.uploader.value = ''
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
          })
      } else {
        this.$store.commit('ALERT', {
          color: 'info',
          text: 'Seleccione una imagen',
        })
      }
    },
  },
}
</script>

<style>
.custom-input-file {
  background-color: #1387d4;
  color: #fff;
  cursor: pointer;
  /* font-size: 18px; */
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto 0;
  min-height: 15px;
  overflow: hidden;
  padding: 10px;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 400px;
  width: 150px;
}
.custom-input-file .input-file {
  border: 10000px solid transparent;
  cursor: pointer;
  font-size: 10000px;
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: -1000px;
  top: -1000px;
}
</style>
